<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-4">
            <div class="row">
                <div class="col-md-12">
                    <label class="required">{{ $t("position.nameEn") }}</label>
                    <input
                        v-model="model.position_name_en"
                        type="text"
                        class="form-control"
                        :placeholder="$t('position.nameEn')"
                    />
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('position_name_en')"
                    >
                        {{ errors.first("position_name_en") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label class="required">{{ $t("position.nameKh") }}</label>
                    <input
                        v-model="model.position_name_kh"
                        type="text"
                        class="form-control"
                        :placeholder="$t('position.nameKh')"
                    />
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('position_name_kh')"
                    >
                        {{ errors.first("position_name_kh") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <ts-checkbox v-model="model.attributes.interchangeable">{{
                        $t("position.interchangeable")
                    }}</ts-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button @click.prevent="() => $emit('cancel')">
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        outline
                        color="info"
                        @click.prevent="onSave"
                        >{{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        >{{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
import { mapState } from "vuex";
export default {
    name: "formPosition",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            model: {
                id: "",
                position_name_en: "",
                position_name_kh: "",
                attributes: {
                    interchangeable: false
                }
            }
        };
    },
    computed: {
        ...mapState("hr/position", ["edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    created() {
        this.setEditData();
    },
    methods: {
        async onSave() {
            this.loading = true;
            this.$store
                .dispatch("hr/position/store", this.model)
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("fetchData");
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async onSaveAddNew() {
            this.loading = true;
            this.$store
                .dispatch("hr/position/store", this.model)
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async onUpdate() {
            this.loading = true;
            this.$store
                .dispatch("hr/position/update", {
                    id: this.model.id,
                    data: this.model
                })
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.id = "";
            this.model.position_name_en = "";
            this.model.position_name_kh = "";
            this.model.attributes = {};
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.id = this.edit_data.id;
                this.model.position_name_en = this.edit_data.position_name_en;
                this.model.position_name_kh = this.edit_data.position_name_kh;
                this.model.attributes = this.edit_data.attributes;
            }
        }
    }
};
</script>
